import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UsageHistoryRequest from '../components/UsageHistoryRequest';

function SMTPage({}) {
  const [usageHistory, setUsageHistory] = useState(null);
  const [showUsageHistoryPrompt, setShowUsageHistoryPrompt] = useState(null);
  const [showUsageHistoryRequest, setShowUsageHistoryRequest] = useState(true);

  return (
    <UsageHistoryRequest
      usageHistory={usageHistory}
      setUsageHistory={setUsageHistory}
      setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
      showUsageHistoryRequest={showUsageHistoryRequest}
      setShowUsageHistoryRequest={setShowUsageHistoryRequest}
    />
  );
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SMTPage);
