import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import WifiAnimation from '../WifiAnimation';
import { LinearProgress } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as dataApi from '../../api/dataApi';

import './index.css';
import AddressSearch from '../AddressSearch';

const $ = typeof window !== 'undefined' ? window.$ : {};

function UsageHistoryRequest({
  serviceAddress,
  texasREPList,
  siteConfig,
  setShowUsageHistoryPrompt,
  usageHistory,
  setUsageHistory,
  monthList,
  actions,
  showUsageHistoryRequest,
  setShowUsageHistoryRequest,
  inline
}) {
  const [activeStep, setActiveStep] = useState(0);
  const stepList = [
    'Enter Your Full Address',
    'Connect to Smart Meter',
    'Approve Smart Meter Request',
    'Download Usage History'
  ];

  const [usageHistoryRequest, setUsageHistoryRequest] = useState({});
  const [repMenuInputValue, setrepMenuInputValue] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const usageCheckTimer = useRef();
  const emailAlreadyReceived = useRef();
  const [emailBody, setEmailBody] = useState(null);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.DeregulatedElectric &&
      serviceAddress.DeregulatedElectric.IsDeregulated &&
      serviceAddress.DeregulatedElectric.IsSmartMeter &&
      (!serviceAddress.ElectricUsageHistory ||
        !serviceAddress.ElectricUsageHistory.Actual) &&
      (dataApi.getCookie('sc-smart-meter-prompted') !== '1' ||
        serviceAddress.DeregulatedElectric.HasUsageRequest)
    ) {
      if (!serviceAddress.smartMeterPrompted) {
        let _usageHistoryRequest = {};

        const usageRequestCookie = dataApi.getCookie('uhr');
        if (usageRequestCookie) {
          try {
            _usageHistoryRequest = JSON.parse(usageRequestCookie);
          } catch (e) {}
        }

        if (!_usageHistoryRequest.DeregulatedAddressID) {
          _usageHistoryRequest.DeregulatedAddressID =
            serviceAddress.DeregulatedElectric.DeregulatedAddressID;
        }

        if (_usageHistoryRequest.ProviderPUCT) {
          setrepMenuInputValue(
            texasREPList.filter(
              (rep) => rep.puct == _usageHistoryRequest.ProviderPUCT
            )[0]
          );
        }

        setUsageHistoryRequest({
          ..._usageHistoryRequest,

          exists: serviceAddress.DeregulatedElectric.HasUsageRequest,
          submitted: false,
          UsageHistorySourceID: 1 //Smart Meter Texas
        });

        setShowUsageHistoryRequest(true);

        if (serviceAddress.DeregulatedElectric.HasUsageRequest) {
          checkUsageHistory();
        }

        actions.trackSmartMeterPrompted();
      }
    }

    if (serviceAddress) {
      if (
        serviceAddress.Address &&
        serviceAddress.Address.Address &&
        activeStep <= 1
      ) {
        setActiveStep(1);
      } else if (activeStep <= 1) {
        setActiveStep(0);
      }
    }
  }, [serviceAddress]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (usageCheckTimer.current) {
        clearTimeout(usageCheckTimer.current);
      }

      emailAlreadyReceived.current = false;
    };
  }, []);

  useEffect(() => {
    if (usageHistoryRequest) {
      dataApi.setCookie('uhr', JSON.stringify({ ...usageHistoryRequest }), 1);
    }
  }, [usageHistoryRequest]);

  function updateEmailForCheckout(value) {
    const personalInfoCookie = dataApi.getCookie('sc-pi');
    let _personalInfo = {};
    if (personalInfoCookie) {
      try {
        _personalInfo = JSON.parse(personalInfoCookie);
      } catch (e) {}
    }

    dataApi.setCookie(
      'pi',
      JSON.stringify({ ..._personalInfo, Email: value }),
      1
    );
  }

  function checkUsageHistory() {
    dataApi
      .getUsageHistory(
        { ...serviceAddress.Address },
        emailAlreadyReceived.current
      )
      .then((result) => {
        if (result) {
          let keepLooking = true;

          if (result.EmailBody) {
            console.log('got the email', result);
            emailAlreadyReceived.current = true;

            setActiveStep(2);

            setEmailBody(result);
          } else if (result.length && result[0].UsageBillingDataID) {
            keepLooking = false;

            setActiveStep(3);

            setUsageHistoryRequest({
              ...usageHistoryRequest,
              submitted: false,
              exists: true
            });

            console.log('usage', result);

            let _usageHistory = usageHistory ? { ...usageHistory } : {};
            let _exists = false;
            result.map((usage) => {
              try {
                if (!isNaN(usage.ActualKWh)) {
                  _usageHistory[monthList[new Date(usage.EndDate).getMonth()]] =
                    usage.ActualKWh;

                  _exists = true;
                }
              } catch (e) {}
            });

            console.log('_exists', _exists);

            if (_exists) {
              usageCheckTimer.current = setTimeout(() => {
                setUsageHistory({ ..._usageHistory, SmartMeter: true });
                setShowUsageHistoryPrompt(true);
                setShowUsageHistoryRequest(false);
              }, 1000);
            }
          } else {
            if (!usageHistoryRequest || !usageHistoryRequest.resubmitting) {
              setUsageHistoryRequest({
                ...usageHistoryRequest,
                submitted: true
              });
            }
          }

          if (keepLooking) {
            usageCheckTimer.current = setTimeout(checkUsageHistory, 5000);
          }
        } else {
          setUsageHistoryRequest({
            ...usageHistoryRequest,
            exists: false,
            submitted: false
          });
        }
      });
  }

  function dismissPrompt() {
    dataApi.setCookie('sc-smart-meter-prompted', '1', 24);
    setShowUsageHistoryRequest(false);
  }

  return showUsageHistoryRequest ? (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="usage-history-request"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            dismissPrompt();
          }}>
          <CloseIcon />
        </IconButton>
        Connect Smart Meter
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <div className="heading">
          {serviceAddress &&
          serviceAddress.DeregulatedElectric &&
          serviceAddress.DeregulatedElectric.IsSmartMeter ? (
            <div className="graphic-container">
              <WifiAnimation />
              <img
                src={withPrefix(
                  '/images/misc/smartmeter_240_F_293926718_sH9MkB7W8AGz4zGozTvDn2QeNLSWHu2z.png'
                )}
                alt="Smart Meter"
                className="meter-image"
              />
            </div>
          ) : null}

          {emailBody && !usageHistoryRequest.exists ? (
            <h2>
              Confirm the
              <br />
              agreement below
            </h2>
          ) : usageHistoryRequest.submitted ? (
            <h2>Initiating agreement with Smart Meter Texas...</h2>
          ) : usageHistoryRequest.exists ? (
            <h2>
              Connecting to your
              <br />
              smart meter...
            </h2>
          ) : serviceAddress &&
            serviceAddress.DeregulatedElectric &&
            serviceAddress.DeregulatedElectric.IsSmartMeter ? (
            <h2>You have a smart meter!</h2>
          ) : null}
        </div>

        <div className="container">
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepList.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {emailBody && !usageHistoryRequest.exists ? (
            <>
              <p>
                <strong>
                  Please confirm the agreement from Smart Meter Texas below. A
                  copy of this agreement was also sent to your email.
                </strong>
              </p>

              <iframe
                id="smtEmail"
                width="100%"
                style={{ width: '100%', height: '300px', minHeight: '0px' }}
                scrolling="auto"
                frameBorder="0"
                src={
                  process.env.GATSBY_API_URL +
                  'home/smtemail?visitorKey=' +
                  dataApi.getCookie('sc-visitor') +
                  '&visitKey=' +
                  dataApi.getSessionStorage('sc-visit') +
                  '&address=' +
                  encodeURIComponent(serviceAddress.Address.Address) +
                  '&secondary=' +
                  encodeURIComponent(serviceAddress.Address.Secondary) +
                  '&city=' +
                  encodeURIComponent(serviceAddress.Address.City) +
                  '&state=' +
                  encodeURIComponent(serviceAddress.Address.State) +
                  '&zip=' +
                  encodeURIComponent(serviceAddress.Address.Zip)
                }></iframe>
            </>
          ) : usageHistoryRequest.submitted ? (
            <>
              <p>
                <strong>
                  Your information has been submitted and we're checking with
                  Smart Meter Texas for approval.
                </strong>
              </p>
              <p>This process usually takes less than a minute.</p>
              {/* <p>
              If you did not receive the email,{' '}
              <a
                className="link"
                id="start-over"
                onClick={event => {
                  setUsageHistoryRequest({
                    ...usageHistoryRequest,
                    submitted: false,
                    resubmitting: true
                  });

                  if (usageCheckTimer.current) {
                    clearTimeout(usageCheckTimer.current);
                  }

                  event.preventDefault();
                }}>
                click here
              </a>{' '}
              to try again.
            </p> */}
            </>
          ) : usageHistoryRequest.exists ? (
            <>Downloading your usage history...</>
          ) : serviceAddress &&
            serviceAddress.Address &&
            serviceAddress.Address.Address ? (
            <>
              <p>
                <strong>
                  We recommend searching plans based on your actual usage
                  history.
                </strong>
              </p>

              <p>
                Enter the details below to automatically pull your usage history
                from your smart meter now.
              </p>

              <p>
                Note: You'll receive an email from{' '}
                <a
                  href="https://www.smartmetertexas.com/aboutus"
                  className="link"
                  target="_blank"
                  id="smt">
                  Smart Meter Texas
                </a>{' '}
                with a link to approve this request.
              </p>

              <TextField
                label="ESI-ID"
                required
                value={usageHistoryRequest.DeregulatedAddressID}
                onChange={(event) =>
                  setUsageHistoryRequest({
                    ...usageHistoryRequest,
                    DeregulatedAddressID: event.target.value
                  })
                }
                variant="outlined"
                style={{ marginBottom: '15px', marginTop: '10px' }}
                fullWidth
                placeholder="See your last bill"
                autoFocus={true}
              />

              <TextField
                label="Meter Number"
                required
                variant="outlined"
                value={usageHistoryRequest.MeterNumber}
                onChange={(event) =>
                  setUsageHistoryRequest({
                    ...usageHistoryRequest,
                    MeterNumber: event.target.value
                  })
                }
                style={{ marginBottom: '15px' }}
                fullWidth
                placeholder="See your last bill"
                autoFocus={true}
              />

              <div className="rep-menu" style={{ marginBottom: '15px' }}>
                <Autocomplete
                  id="repMenuComboBox"
                  options={texasREPList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Current Electric Provider"
                      label="Current Electric Provider"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  value={repMenuInputValue}
                  onChange={(event, newValue) => {
                    setrepMenuInputValue(newValue);
                    if (newValue && newValue.puct) {
                      setUsageHistoryRequest({
                        ...usageHistoryRequest,
                        ProviderPUCT: newValue.puct
                      });
                    }
                  }}
                />
              </div>

              <TextField
                label="Email address"
                type="email"
                required
                value={usageHistoryRequest.Email}
                onChange={(event) => {
                  setUsageHistoryRequest({
                    ...usageHistoryRequest,
                    Email: event.target.value
                  });

                  updateEmailForCheckout(event.target.value);
                }}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
                placeholder="Required by Smart Meter Texas"
              />

              <FormControlLabel
                style={{ display: 'table' }}
                control={
                  <div style={{ display: 'table-cell' }}>
                    <Checkbox
                      checked={usageHistoryRequest.Authorized}
                      onChange={(event) => {
                        setUsageHistoryRequest({
                          ...usageHistoryRequest,
                          Authorized: event.target.checked
                        });
                      }}
                    />
                  </div>
                }
                label={
                  'I authorize ' +
                  siteConfig.brand +
                  ' to access Smart Meter Texas on my behalf and pull my usage history.'
                }
              />
            </>
          ) : (
            <>
              <AddressSearch
                mode="address"
                hideSearchBy
                addressLabel="Enter your address to connect your smart meter"
              />
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <>
          {saving ? (
            <div
              style={{
                margin: '-10px',
                marginBottom: '10px'
              }}>
              <LinearProgress />
            </div>
          ) : null}

          {saveError ? <div className="error">{saveError}</div> : null}
          {!emailBody &&
          showUsageHistoryRequest &&
          !showUsageHistoryRequest.inline ? (
            <div style={{ float: 'left' }}>
              <Button
                onClick={() => {
                  setShowUsageHistoryPrompt(true);
                  setShowUsageHistoryRequest(false);
                }}
                className="link">
                Enter Usage Manually
              </Button>
            </div>
          ) : null}
          {!usageHistoryRequest.exists && !usageHistoryRequest.submitted ? (
            <Button
              color="secondary"
              disabled={
                !usageHistoryRequest.DeregulatedAddressID ||
                !usageHistoryRequest.MeterNumber ||
                !usageHistoryRequest.Email ||
                !usageHistoryRequest.ProviderPUCT ||
                !usageHistoryRequest.Authorized ||
                saving
              }
              variant="contained"
              onClick={() => {
                setSaving(true);
                setSaveError(null);
                emailAlreadyReceived.current = false;

                let providerPUCT = usageHistoryRequest.ProviderPUCT;

                switch (usageHistoryRequest.DeregulatedAddressID) {
                  case '90000000000000187':
                  case '90000000000000188':
                  case '90000000000000189':
                    providerPUCT = '22222';
                    break;
                  default:
                    break;
                }

                dataApi
                  .saveUsageRequest({
                    ...usageHistoryRequest,
                    ProviderPUCT: providerPUCT,
                    UsageHistorySourceID: 1,
                    ServiceAddress: { ...serviceAddress.Address }
                  })
                  .then((result) => {
                    if (result === 'SUCCESS') {
                      setUsageHistoryRequest({
                        ...usageHistoryRequest,
                        submitted: true,
                        exists: true
                      });

                      checkUsageHistory();

                      setActiveStep(2);
                    } else {
                      setSaveError(result);
                    }

                    setSaving(false);
                  });
              }}>
              {saving ? 'Submitting...' : 'Submit Request'}
            </Button>
          ) : null}
          {!emailBody ? (
            <Button
              onClick={() => {
                dismissPrompt();
              }}
              variant="outlined">
              Skip for Now
            </Button>
          ) : null}
        </>
      </DialogActions>
    </Dialog>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    texasREPList: state.commonData.texasREPList,
    siteConfig: state.commonData.siteConfig,
    monthList: state.commonData.monthList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      trackSmartMeterPrompted: bindActionCreators(
        serviceAddressActions.trackSmartMeterPrompted,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsageHistoryRequest);
